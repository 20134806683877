<script setup>
import { ref } from 'vue';
import { getOrCreateDataObject } from 'o365-dataobject';
import { confirm } from 'o365-vue-services';
import Tasks from "sja.vue.libraries.components.Tasks.vue";
import MobileTasks from "sja.vue.libraries.mob.MobileTasks.vue";
import Reasons from "sja.vue.libraries.components.Reasons.vue";
import { procCreateSJA, procDeleteSJA, expandAll, collapseAll } from "sja.vue.libraries.modules.utilities.js";
import { isMobile } from 'o365.GlobalState.ts';
import { OActionList, OActionItem } from "o365-ui-components";

const props = defineProps({
        itemId: Number,
        stepId: Number,
        readOnly: Boolean,
        workflows: Object,
        objects: Object,
});

let isSJA = ref(false);
let SJA_ID = null;
let isChecked = ref(true);
let screenWidth = window.innerWidth;

const dsSJA = getOrCreateDataObject({
        id: `dsSJA_${props.itemId}${props.stepId ? '_' + props.stepId : ''}`,
        uniqueTable: 'atbv_SJA_SJA',
        selectFirstRowOnLoad: true,
        maxRecords: 1,
        viewName: 'aviw_SJA_SJA',
        allowUpdate: true,
        allowInsert: true,
        allowDelete: true,
        disableLayouts: true,
        fields: [
                { name: 'PrimKey', },
                { name: 'ID', },
                { name: 'Step_ID', },
                { name: 'Name', },
                { name: 'Description', },
                { name: 'Planned', },
                { name: 'Completed', },
                { name: 'OrgUnit_ID', },
                { name: 'OrgUnitIdPath', },
                { name: 'OrgUnit', },
                { name: 'Responsible_ID', },
                { name: 'Responsible', },
                { name: 'IsTemplate', },
                { name: 'Deleted', },
                { name: 'Checklist_ID', },
                { name: 'CheckList_Editable', },
                { name: 'IDSrc', },
                { name: 'Checklist', }
        ],
        whereClause: `Deleted = 0 ${props.stepId ? "AND Step_ID = " + props.stepId : 'AND Workflow_ID = ' + props.itemId + ' AND Step_ID IS NULL'}`
});

async function checkForSJA() {
        await dsSJA.load();
        if (dsSJA.data.length > 0) {
                SJA_ID = dsSJA.current.ID;
                isSJA.value = true;
                if (props.readOnly == true && !dsSJA.current.Completed) {
                        dsSJA.current.Completed = new Date().toUTCString();
                        dsSJA.save();
                }
        } else {
                isSJA.value = false;
        }
}

async function createSJA() {
        confirm({
                title: $t("Starting a Safe Job Analysis"),
                message: $t("You are now creating a SJA for this step.") + ' ' + $t("A SJA is a step by step risk evaluation to be performed before work commences") + ' ' + $t("on a task to reduce the risk to workers by determining hazards and taking actions to improve the potential outcome of an incident.") + ' ' + $t("Are you sure you wish to create a SJA for this step?"),
                btnTextOk: $t("Yes"),
                btnTextCancel: $t("No"),
        }).then(async () => {
                await procCreateSJA.execute({ Step_ID: props.stepId ? props.stepId : null, Item_ID: props.itemId });
                checkForSJA();
        }).catch(() => {
        });
}

async function deleteSJA() {
        confirm({
                title: $t("Delete Safe Job Analysis"),
                message: $t('Are you sure you wish to delete this SJA?'),
                btnTextOk: $t("Yes"),
                btnTextCancel: $t("No"),
        }).then(async () => {
                await procDeleteSJA.execute({ SJAID: dsSJA.current.ID, SJAPrimKey: dsSJA.current.PrimKey });
                await dsSJA.load()
                checkForSJA();
        }).catch(() => {
        });
}

function handleCheckboxChange(sjaID) {
        if (isChecked.value) {
                collapseAll(sjaID);
        } else {
                expandAll(sjaID);
        }
}

checkForSJA();

</script>

<template>
        <div class="d-flex"> <button class="btn btn-primary create-sja" @click="createSJA()"
                        v-if="isSJA == false && readOnly == false"><i class="bi bi-cone-striped me-1"></i>
                        {{ $t('Create SJA') }}
                </button>
        </div>
        <div v-if="isSJA" class="d-flex flex-column card p-3">
                <div class="d-flex">
                        <h5 class="me-auto">{{ $t('SJA Details') }}</h5>

                        <OActionList v-if="isMobile && !dsSJA.current.Completed" mReverseOrder>
                                <template #target="{ target }">
                                        <a :ref="target" v-bind="$attrs">
                                                <i class="ms-auto me-2 bi bi-three-dots-vertical fs-4" type="button"
                                                        data-bs-toggle="dropdown" aria-expanded="false"></i>
                                        </a>
                                </template>
                                <OActionItem v-if="!dsSJA.current.Completed" class="text-danger" :text="$t('Delete SJA')"
                                        @click="deleteSJA()" />
                        </OActionList>

                        <i v-else v-if="!dsSJA.current.Completed" class="ms-auto me-2 bi bi-three-dots-vertical fs-4"
                                type="button" data-bs-toggle="dropdown" aria-expanded="false"></i>
                        <ul class="dropdown-menu">
                                <li>
                                        <button @click="deleteSJA()" v-if="!dsSJA.current.Completed"
                                                class="btn btn-outline-danger btn-sm dropdown-item">
                                                {{ $t('Delete SJA') }}
                                        </button>
                                </li>
                        </ul>
                </div>
                <a :href="dsSJA.current.IDSrc" :title="$t('Link to SJA')" target="_blank">{{ 'ID: ' }}{{ SJA_ID }} </a>
                <hr class="m-0" />
                <div class="pt-1">
                        <Reasons :readOnly="!dsSJA.current.Completed ? false : true" :sjaID="SJA_ID"></Reasons>
                </div>
                <div class="mb-1 mt-3 d-flex align-items-center">
                        <h5>{{ $t('Tasks') }}</h5>
                        <div v-if="!isMobile && screenWidth > 1150" class="ms-3 form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" :id="'SwitchHazards' + SJA_ID"
                                        v-model="isChecked" @click="handleCheckboxChange(SJA_ID)">
                                <label class="form-check-label" :for="'SwitchHazards' + SJA_ID">
                                        {{ $t('Toggle Hazards') }}
                                </label>
                        </div>
                </div>
                <hr class="m-0" />
                <div class="overflow-x-auto">
                        <MobileTasks v-if="isMobile || screenWidth <= 1150" :readOnly="!dsSJA.current.Completed ? false : true"
                                :sjaID="SJA_ID" :itemID="props.itemId" :participants="false" :showTasks="false"></MobileTasks>
                        <Tasks v-else :readOnly="!dsSJA.current.Completed ? false : true" :sjaID="SJA_ID" :itemID="props.itemId"
                                :participants="false"></Tasks>
                </div>
        </div>
</template>